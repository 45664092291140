<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout pb-2 wrap justify-center>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Edit Region</span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 wrap justify-space-around>
                <v-flex xs12 sm12 md4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        ref="countryName"
                        :rules="[rules.required]"
                        v-model="countryName"
                        prepend-icon="mdi-earth"
                        label="Country Name"
                        color="#26af82"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        ref="dialCode"
                        :rules="[rules.required]"
                        v-model="dialCode"
                        prepend-icon="mdi-phone"
                        label="Dial Code"
                        color="#26af82"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <!-- <v-text-field
                        ref="supportingEvidenceName"
                        :rules="[rules.required]"
                        v-model="supportingEvidenceName"
                        prepend-icon="mdi-file-document"
                        label="Supporting Evidence Name"
                        color="#26af82"
                        required
                      ></v-text-field>-->
                      <v-combobox
                        v-model="supportingEvidenceName"
                        prepend-icon="mdi-file-document"
                        label="Supporting Evidence Names"
                        multiple
                        color="#26af82"
                        small-chips
                        :clearable="true"
                      ></v-combobox>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-textarea
                        ref="tmcandpvp"
                        v-model="tmcandpvp"
                        :rules="[rules.required]"
                        prepend-icon="mdi-thumb-up"
                        label="Terms and Conditions & Privacy Policy"
                        color="#26af82"
                        required
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-textarea
                        ref="termsAndConditions"
                        v-model="termsAndConditions"
                        :rules="[rules.required]"
                        prepend-icon="mdi-thumb-up"
                        label="Terms and Conditions"
                        color="#26af82"
                        required
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-textarea
                        ref="privacyPolicy"
                        v-model="privacyPolicy"
                        :rules="[rules.required]"
                        prepend-icon="mdi-format-align-justify"
                        label="Privacy Policy"
                        color="#26af82"
                        required
                      ></v-textarea>
                    </v-flex>
                  </v-layout>-->
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center>
                <v-flex xs12 sm12 md4 text-center>
                  <v-btn
                    @click="addProject"
                    rounded
                    small
                    dark
                    :ripple="false"
                    class="text-capitalize"
                    outlined
                    color="#26af82"
                    width="250"
                  >Save Region</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      countryName: null,
      dialCode: null,
      supportingEvidenceName: [],
      tmcandpvp: null,
      termsAndConditions: null,
      privacyPolicy: null,
      rules: {
        required: value => !!value || "Required",
        number: value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Invalid Input";
        }
      }
    };
  },
  beforeMount() {
    this.getRegion();
  },
  methods: {
    getRegion() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/country/get",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.countryName = response.data.data.country;
          this.dialCode = response.data.data.dialcode;
          this.supportingEvidenceName = response.data.data.evidence;
          this.tmcandpvp = response.data.data.tmcandpvp;
          // this.termsAndConditions = response.data.data.terms;
          // this.privacyPolicy = response.data.data.privacy;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
    addProject() {
      this.appLoading = true;
      var data = {};
      data["country"] = this.countryName;
      data["dialcode"] = this.dialCode;
      data["evidence"] = this.supportingEvidenceName;
      data["tmcandpvp"] = this.tmcandpvp;
      // data["terms"] = this.termsAndConditions;
      // data["privacy"] = this.privacyPolicy;
      data["id"] = this.id;
      axios({
        method: "POST",
        url: "/country/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          if (response.data.status) {
            this.$router.push("/Regions");
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>